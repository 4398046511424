@font-face {
  font-family: 'roysha-icon';
  src:  url('../fonts/roysha-icon9a279a27.eot?h1me7s');
  src:  url('../fonts/roysha-icon9a279a27.eot?h1me7s#iefix') format('embedded-opentype'),
    url('../fonts/roysha-icon9a279a27.ttf?h1me7s') format('truetype'),
    url('../fonts/roysha-icon9a279a27.woff?h1me7s') format('woff'),
    url('../fonts/roysha-icon9a279a27.svg?h1me7s#roysha-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.roysha-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'roysha-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roysha-amount .path1:before {
  content: "\e900";
  opacity: .2;
}
.roysha-amount .path2:before {
  content: "\e901";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path3:before {
  content: "\e902";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path4:before {
  content: "\e903";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path5:before {
  content: "\e904";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path6:before {
  content: "\e905";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path7:before {
  content: "\e906";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path8:before {
  content: "\e907";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path9:before {
  content: "\e908";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-amount .path10:before {
  content: "\e909";
  margin-left: -1.033203125em;
  opacity: .2;
}
.roysha-amount .path11:before {
  content: "\e90a";
  margin-left: -1.033203125em;
  color: rgb(37, 107, 230);
}
.roysha-api .path1:before {
  content: "\e90b";
  color: rgb(37, 107, 230);
}
.roysha-api .path2:before {
  content: "\e90c";
  margin-left: -0.984375em;
  color: rgb(37, 107, 230);
}
.roysha-api .path3:before {
  content: "\e90d";
  margin-left: -0.984375em;
  opacity: .2;
}
.roysha-api .path4:before {
  content: "\e90e";
  margin-left: -0.984375em;
  opacity: .2;
}
.roysha-ask .path1:before {
  content: "\e90f";
  opacity: .2;
}
.roysha-ask .path2:before {
  content: "\e910";
  margin-left: -0.7783203125em;
  color: rgb(37, 107, 230);
}
.roysha-ask .path3:before {
  content: "\e911";
  margin-left: -0.7783203125em;
  color: rgb(37, 107, 230);
}
.roysha-ask .path4:before {
  content: "\e912";
  margin-left: -0.7783203125em;
  color: rgb(37, 107, 230);
}
.roysha-ask .path5:before {
  content: "\e913";
  margin-left: -0.7783203125em;
  opacity: .2;
}
.roysha-bank .path1:before {
  content: "\e914";
  opacity: .2;
}
.roysha-bank .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank .path5:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank .path6:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path1:before {
  content: "\e91a";
  opacity: .2;
}
.roysha-bank-diposite .path2:before {
  content: "\e91b";
  margin-left: -1em;
  opacity: .2;
}
.roysha-bank-diposite .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(232, 239, 238);
}
.roysha-bank-diposite .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path11:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path12:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path13:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path14:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path15:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite .path16:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite2 .path1:before {
  content: "\e92a";
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite2 .path2:before {
  content: "\e92b";
  margin-left: -1.0205078125em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite2 .path3:before {
  content: "\e92c";
  margin-left: -1.0205078125em;
  color: rgb(37, 107, 230);
}
.roysha-bank-diposite2 .path4:before {
  content: "\e92d";
  margin-left: -1.0205078125em;
  opacity: .2;
}
.roysha-bank-diposite2 .path5:before {
  content: "\e92e";
  margin-left: -1.0205078125em;
  opacity: .2;
}
.roysha-cash .path1:before {
  content: "\e92f";
  opacity: .2;
}
.roysha-cash .path2:before {
  content: "\e930";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash .path3:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path4:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path5:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path6:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path7:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path8:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash .path9:before {
  content: "\e937";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash .path10:before {
  content: "\e938";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash-pin .path1:before {
  content: "\e939";
  opacity: .2;
}
.roysha-cash-pin .path2:before {
  content: "\e93a";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash-pin .path3:before {
  content: "\e93b";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash-pin .path4:before {
  content: "\e93c";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash-pin .path5:before {
  content: "\e93d";
  margin-left: -1em;
  opacity: .2;
}
.roysha-cash-pin .path6:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash-pin .path7:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash-pin .path8:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash-pin .path9:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash-pin .path10:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-cash-pin .path11:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-diposite .path1:before {
  content: "\e944";
  color: rgb(37, 107, 230);
}
.roysha-diposite .path2:before {
  content: "\e945";
  margin-left: -1.01953125em;
  color: rgb(37, 107, 230);
}
.roysha-diposite .path3:before {
  content: "\e946";
  margin-left: -1.01953125em;
  color: rgb(37, 107, 230);
}
.roysha-diposite .path4:before {
  content: "\e947";
  margin-left: -1.01953125em;
  opacity: .2;
}
.roysha-diposite .path5:before {
  content: "\e948";
  margin-left: -1.01953125em;
  opacity: .2;
}
.roysha-encrypted .path1:before {
  content: "\e949";
  opacity: .2;
}
.roysha-encrypted .path2:before {
  content: "\e94a";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-encrypted .path3:before {
  content: "\e94b";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-encrypted .path4:before {
  content: "\e94c";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path5:before {
  content: "\e94d";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path6:before {
  content: "\e94e";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path7:before {
  content: "\e94f";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-encrypted .path8:before {
  content: "\e950";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-encrypted .path9:before {
  content: "\e951";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path10:before {
  content: "\e952";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-encrypted .path11:before {
  content: "\e953";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path12:before {
  content: "\e954";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-encrypted .path13:before {
  content: "\e955";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path1:before {
  content: "\e956";
  opacity: .2;
}
.roysha-fast-transfer .path2:before {
  content: "\e957";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path3:before {
  content: "\e958";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path4:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path5:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path6:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path7:before {
  content: "\e95c";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path8:before {
  content: "\e95d";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path9:before {
  content: "\e95e";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path10:before {
  content: "\e95f";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path11:before {
  content: "\e960";
  margin-left: -1em;
  opacity: .2;
}
.roysha-fast-transfer .path12:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path13:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path14:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path15:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path16:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path17:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-fast-transfer .path18:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-form .path1:before {
  content: "\e968";
  opacity: .2;
}
.roysha-form .path2:before {
  content: "\e969";
  margin-left: -0.90234375em;
  opacity: .2;
}
.roysha-form .path3:before {
  content: "\e96a";
  margin-left: -0.90234375em;
  opacity: .2;
}
.roysha-form .path4:before {
  content: "\e96b";
  margin-left: -0.90234375em;
  opacity: .2;
}
.roysha-form .path5:before {
  content: "\e96c";
  margin-left: -0.90234375em;
  opacity: .2;
}
.roysha-form .path6:before {
  content: "\e96d";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path7:before {
  content: "\e96e";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path8:before {
  content: "\e96f";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path9:before {
  content: "\e970";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path10:before {
  content: "\e971";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path11:before {
  content: "\e972";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path12:before {
  content: "\e973";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path13:before {
  content: "\e974";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path14:before {
  content: "\e975";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path15:before {
  content: "\e976";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path16:before {
  content: "\e977";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path17:before {
  content: "\e978";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path18:before {
  content: "\e979";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path19:before {
  content: "\e97a";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-form .path20:before {
  content: "\e97b";
  margin-left: -0.90234375em;
  color: rgb(37, 107, 230);
}
.roysha-globe .path1:before {
  content: "\e97c";
  color: rgb(37, 107, 230);
}
.roysha-globe .path2:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-globe .path3:before {
  content: "\e97e";
  margin-left: -1em;
  opacity: .2;
}
.roysha-globe .path4:before {
  content: "\e97f";
  margin-left: -1em;
  opacity: .2;
}
.roysha-globe .path5:before {
  content: "\e980";
  margin-left: -1em;
  opacity: .2;
}
.roysha-globe .path6:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-globe .path7:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path1:before {
  content: "\e983";
  opacity: .2;
}
.roysha-hand .path2:before {
  content: "\e984";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path3:before {
  content: "\e985";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path4:before {
  content: "\e986";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path5:before {
  content: "\e987";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path6:before {
  content: "\e988";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path7:before {
  content: "\e989";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path8:before {
  content: "\e98a";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path9:before {
  content: "\e98b";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path10:before {
  content: "\e98c";
  margin-left: -0.7138671875em;
  opacity: .2;
}
.roysha-hand .path11:before {
  content: "\e98d";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path12:before {
  content: "\e98e";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path13:before {
  content: "\e98f";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path14:before {
  content: "\e990";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path15:before {
  content: "\e991";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path16:before {
  content: "\e992";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand .path17:before {
  content: "\e993";
  margin-left: -0.7138671875em;
  color: rgb(37, 107, 230);
}
.roysha-hand-bag-cash .path1:before {
  content: "\e994";
  opacity: .2;
}
.roysha-hand-bag-cash .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-bag-cash .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-bag-cash .path4:before {
  content: "\e997";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-bag-cash .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(232, 239, 238);
}
.roysha-hand-bag-cash .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-bag-cash .path7:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path1:before {
  content: "\e99b";
  opacity: .2;
}
.roysha-hand-card .path2:before {
  content: "\e99c";
  margin-left: -0.7001953125em;
  opacity: .2;
}
.roysha-hand-card .path3:before {
  content: "\e99d";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path4:before {
  content: "\e99e";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path5:before {
  content: "\e99f";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path6:before {
  content: "\e9a0";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path7:before {
  content: "\e9a1";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path8:before {
  content: "\e9a2";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path9:before {
  content: "\e9a3";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path10:before {
  content: "\e9a4";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-card .path11:before {
  content: "\e9a5";
  margin-left: -0.7001953125em;
  color: rgb(37, 107, 230);
}
.roysha-hand-cash .path1:before {
  content: "\e9a6";
  color: rgb(37, 107, 230);
}
.roysha-hand-cash .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-cash .path3:before {
  content: "\e9a8";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-cash .path4:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-cash .path5:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hand-cash .path6:before {
  content: "\e9ab";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-cash .path7:before {
  content: "\e9ac";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-cash .path8:before {
  content: "\e9ad";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-cash .path9:before {
  content: "\e9ae";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hand-cash .path10:before {
  content: "\e9af";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hight-benefit .path1:before {
  content: "\e9b0";
  opacity: .2;
}
.roysha-hight-benefit .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hight-benefit .path3:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hight-benefit .path4:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hight-benefit .path5:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-hight-benefit .path6:before {
  content: "\e9b5";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hight-benefit .path7:before {
  content: "\e9b6";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hight-benefit .path8:before {
  content: "\e9b7";
  margin-left: -1em;
  opacity: .2;
}
.roysha-hight-benefit .path9:before {
  content: "\e9b8";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path1:before {
  content: "\e9b9";
  opacity: .2;
}
.roysha-low-cost .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path4:before {
  content: "\e9bc";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path5:before {
  content: "\e9bd";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path6:before {
  content: "\e9be";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path7:before {
  content: "\e9bf";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path8:before {
  content: "\e9c0";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path9:before {
  content: "\e9c1";
  margin-left: -1em;
  opacity: .2;
}
.roysha-low-cost .path10:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path11:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path12:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path13:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path14:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path15:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path16:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path17:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path18:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path19:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path20:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path21:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path22:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path23:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path24:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path25:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path26:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path27:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path28:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path29:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-low-cost .path30:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-marker .path1:before {
  content: "\e9d7";
  opacity: .2;
}
.roysha-marker .path2:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-marker .path3:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-marker .path4:before {
  content: "\e9da";
  margin-left: -1em;
  opacity: .2;
}
.roysha-marker .path5:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-marker .path6:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path1:before {
  content: "\e9dd";
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path2:before {
  content: "\e9de";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path3:before {
  content: "\e9df";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path4:before {
  content: "\e9e0";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path5:before {
  content: "\e9e1";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path6:before {
  content: "\e9e2";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path7:before {
  content: "\e9e3";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path8:before {
  content: "\e9e4";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path9:before {
  content: "\e9e5";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path10:before {
  content: "\e9e6";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-app .path11:before {
  content: "\e9e7";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path12:before {
  content: "\e9e8";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path13:before {
  content: "\e9e9";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path14:before {
  content: "\e9ea";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path15:before {
  content: "\e9eb";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path16:before {
  content: "\e9ec";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-app .path17:before {
  content: "\e9ed";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-mobile-bank .path1:before {
  content: "\e9ee";
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path2:before {
  content: "\e9ef";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path3:before {
  content: "\e9f0";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path4:before {
  content: "\e9f1";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path5:before {
  content: "\e9f2";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path6:before {
  content: "\e9f3";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path7:before {
  content: "\e9f4";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path8:before {
  content: "\e9f5";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path9:before {
  content: "\e9f6";
  margin-left: -0.9501953125em;
  opacity: .2;
}
.roysha-mobile-bank .path10:before {
  content: "\e9f7";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path11:before {
  content: "\e9f8";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path12:before {
  content: "\e9f9";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path13:before {
  content: "\e9fa";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-mobile-bank .path14:before {
  content: "\e9fb";
  margin-left: -0.9501953125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path1:before {
  content: "\e9fc";
  opacity: .2;
}
.roysha-money-tracking .path2:before {
  content: "\e9fd";
  margin-left: -0.89453125em;
  opacity: .2;
}
.roysha-money-tracking .path3:before {
  content: "\e9fe";
  margin-left: -0.89453125em;
  opacity: .2;
}
.roysha-money-tracking .path4:before {
  content: "\e9ff";
  margin-left: -0.89453125em;
  opacity: .2;
}
.roysha-money-tracking .path5:before {
  content: "\ea00";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path6:before {
  content: "\ea01";
  margin-left: -0.89453125em;
  opacity: .2;
}
.roysha-money-tracking .path7:before {
  content: "\ea02";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path8:before {
  content: "\ea03";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path9:before {
  content: "\ea04";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path10:before {
  content: "\ea05";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path11:before {
  content: "\ea06";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path12:before {
  content: "\ea07";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path13:before {
  content: "\ea08";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path14:before {
  content: "\ea09";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-money-tracking .path15:before {
  content: "\ea0a";
  margin-left: -0.89453125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path1:before {
  content: "\ea0b";
  opacity: .2;
}
.roysha-online-pay .path2:before {
  content: "\ea0c";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path3:before {
  content: "\ea0d";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path4:before {
  content: "\ea0e";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path5:before {
  content: "\ea0f";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path6:before {
  content: "\ea10";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path7:before {
  content: "\ea11";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path8:before {
  content: "\ea12";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path9:before {
  content: "\ea13";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path10:before {
  content: "\ea14";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path11:before {
  content: "\ea15";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path12:before {
  content: "\ea16";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path13:before {
  content: "\ea17";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path14:before {
  content: "\ea18";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path15:before {
  content: "\ea19";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path16:before {
  content: "\ea1a";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path17:before {
  content: "\ea1b";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path18:before {
  content: "\ea1c";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path19:before {
  content: "\ea1d";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path20:before {
  content: "\ea1e";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path21:before {
  content: "\ea1f";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path22:before {
  content: "\ea20";
  margin-left: -0.9658203125em;
  color: rgb(37, 107, 230);
}
.roysha-online-pay .path23:before {
  content: "\ea21";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-online-pay .path24:before {
  content: "\ea22";
  margin-left: -0.9658203125em;
  opacity: .2;
}
.roysha-pickup .path1:before {
  content: "\ea23";
  opacity: .2;
}
.roysha-pickup .path2:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-pickup .path3:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-pickup .path4:before {
  content: "\ea26";
  margin-left: -1em;
  opacity: .2;
}
.roysha-pickup .path5:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(232, 239, 238);
}
.roysha-pickup .path6:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-pickup .path7:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-safe .path1:before {
  content: "\ea2a";
  opacity: .2;
}
.roysha-safe .path2:before {
  content: "\ea2b";
  margin-left: -0.7412109375em;
  color: rgb(37, 107, 230);
}
.roysha-safe .path3:before {
  content: "\ea2c";
  margin-left: -0.7412109375em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path1:before {
  content: "\ea2d";
  opacity: .2;
}
.roysha-secured .path2:before {
  content: "\ea2e";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-secured .path3:before {
  content: "\ea2f";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-secured .path4:before {
  content: "\ea30";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path5:before {
  content: "\ea31";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path6:before {
  content: "\ea32";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path7:before {
  content: "\ea33";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-secured .path8:before {
  content: "\ea34";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-secured .path9:before {
  content: "\ea35";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path10:before {
  content: "\ea36";
  margin-left: -1.1123046875em;
  opacity: .2;
}
.roysha-secured .path11:before {
  content: "\ea37";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path12:before {
  content: "\ea38";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-secured .path13:before {
  content: "\ea39";
  margin-left: -1.1123046875em;
  color: rgb(37, 107, 230);
}
.roysha-support .path1:before {
  content: "\ea3a";
  color: rgb(37, 107, 230);
}
.roysha-support .path2:before {
  content: "\ea3b";
  margin-left: -1.0439453125em;
  opacity: .2;
}
.roysha-support .path3:before {
  content: "\ea3c";
  margin-left: -1.0439453125em;
  opacity: .2;
}
.roysha-support .path4:before {
  content: "\ea3d";
  margin-left: -1.0439453125em;
  opacity: .2;
}
.roysha-support .path5:before {
  content: "\ea3e";
  margin-left: -1.0439453125em;
  opacity: .2;
}
.roysha-tracking .path1:before {
  content: "\ea3f";
  color: rgb(37, 107, 230);
}
.roysha-tracking .path2:before {
  content: "\ea40";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path3:before {
  content: "\ea41";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path4:before {
  content: "\ea42";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path5:before {
  content: "\ea43";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path6:before {
  content: "\ea44";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path7:before {
  content: "\ea45";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path8:before {
  content: "\ea46";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path9:before {
  content: "\ea47";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path10:before {
  content: "\ea48";
  margin-left: -0.833984375em;
  color: rgb(37, 107, 230);
}
.roysha-tracking .path11:before {
  content: "\ea49";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path12:before {
  content: "\ea4a";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path13:before {
  content: "\ea4b";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path14:before {
  content: "\ea4c";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path15:before {
  content: "\ea4d";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path16:before {
  content: "\ea4e";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-tracking .path17:before {
  content: "\ea4f";
  margin-left: -0.833984375em;
  opacity: .2;
}
.roysha-trusted .path1:before {
  content: "\ea50";
  opacity: .2;
}
.roysha-trusted .path2:before {
  content: "\ea51";
  margin-left: -1em;
  opacity: .2;
}
.roysha-trusted .path3:before {
  content: "\ea52";
  margin-left: -1em;
  opacity: .2;
}
.roysha-trusted .path4:before {
  content: "\ea53";
  margin-left: -1em;
  opacity: .2;
}
.roysha-trusted .path5:before {
  content: "\ea54";
  margin-left: -1em;
  opacity: .2;
}
.roysha-trusted .path6:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted .path7:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted .path8:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted .path9:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted .path10:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted .path11:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path1:before {
  content: "\ea5b";
  opacity: .2;
}
.roysha-trusted-client .path2:before {
  content: "\ea5c";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path3:before {
  content: "\ea5d";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path4:before {
  content: "\ea5e";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path5:before {
  content: "\ea5f";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path6:before {
  content: "\ea60";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path7:before {
  content: "\ea61";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path8:before {
  content: "\ea62";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path9:before {
  content: "\ea63";
  margin-left: -1.19921875em;
  opacity: .2;
}
.roysha-trusted-client .path10:before {
  content: "\ea64";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path11:before {
  content: "\ea65";
  margin-left: -1.19921875em;
  opacity: .2;
}
.roysha-trusted-client .path12:before {
  content: "\ea66";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path13:before {
  content: "\ea67";
  margin-left: -1.19921875em;
  opacity: .2;
}
.roysha-trusted-client .path14:before {
  content: "\ea68";
  margin-left: -1.19921875em;
  color: rgb(37, 107, 230);
}
.roysha-trusted-client .path15:before {
  content: "\ea69";
  margin-left: -1.19921875em;
  opacity: .2;
}
.roysha-wallet .path1:before {
  content: "\ea6a";
  opacity: .2;
}
.roysha-wallet .path2:before {
  content: "\ea6b";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path3:before {
  content: "\ea6c";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path4:before {
  content: "\ea6d";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path5:before {
  content: "\ea6e";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path6:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-wallet .path7:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-wallet .path8:before {
  content: "\ea71";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path9:before {
  content: "\ea72";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path10:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-wallet .path11:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-wallet .path12:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(37, 107, 230);
}
.roysha-wallet .path13:before {
  content: "\ea76";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet .path14:before {
  content: "\ea77";
  margin-left: -1em;
  opacity: .2;
}
.roysha-wallet-cashout .path1:before {
  content: "\ea78";
  color: rgb(37, 107, 230);
}
.roysha-wallet-cashout .path2:before {
  content: "\ea79";
  margin-left: -0.96875em;
  color: rgb(37, 107, 230);
}
.roysha-wallet-cashout .path3:before {
  content: "\ea7a";
  margin-left: -0.96875em;
  opacity: .2;
}
.roysha-wallet-cashout .path4:before {
  content: "\ea7b";
  margin-left: -0.96875em;
  opacity: .2;
}
.roysha-wallet-cashout .path5:before {
  content: "\ea7c";
  margin-left: -0.96875em;
  opacity: .2;
}
.roysha-wallet-cashout .path6:before {
  content: "\ea7d";
  margin-left: -0.96875em;
  opacity: .2;
}
