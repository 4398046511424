@tailwind base;
@tailwind components;
@tailwind utilities;


input {
    appearance: none !important;
}
.select input {
    border: none !important;
}
.reciever .form-group{
    margin-bottom: 8px !important;
}

 .header04 .primary-menu ul li a {
    color: black;
}

.header04 .primary-menu ul li a:hover {
    color: #366ADF;
}